import React, { useState } from "react"

export const listConnect = [
  {
    icon: "/assetss/icons/facebook.png",
    link: "https://www.facebook.com/people/Heroes-Land-Match-3-RPG/100087594000171/",
  },
  // { icon: "/assetss/icons/twitter.png", link: "https://twitter.com/HeroesLand_P2E" },
  // { icon: "/assetss/icons/telegram.png", link: "https://t.me/HeroesLand_P2E_ANN" },
  // { icon: "/assetss/icons/discord.png", link: "https://discord.gg/VpmVD3sn4n" },
  // { icon: "/assetss/icons/medium.png", link: "https://heroesland.medium.com/" },
]

export const listLink = [
  { name: "HOME", link: "" },
  { name: "WHITEPAPER", link: "https://whitepaper.heroesland.io" },
  { name: "FAQ", link: "" },
  { name: "SUPPORT", link: "" },
]

export const listLink2 = [
  // { name: "MARKETPLACE", link: "https://whitepaper.heroesland.io/market-overview/market-overview" },
  { name: "PRIVACY POLICY", link: "https://docs.heroesland.games/heroes-land-privacy-policy" },
  { name: "TERMS OF USE", link: "https://docs.heroesland.games/heroes-land-terms-of-service" },
  { name: "SUPPORT", link: "https://docs.heroesland.games/heroes-land-support" },
  // { name: "DISCLAIMER", link: "https://whitepaper.heroesland.io/policy/disclaimer" },
]

const Footer = ({ spineStatus }) => {
  const [email, setEmail] = useState("")

  const handleChangeEmail = (e) => {
    setEmail(e.target.value)
  }

  return (
    <footer
      id="footer"
      className={`section relative min-w-screen min-h-screen bg-transparent !flex flex-col items-center justify-center mx-auto p-10 md:p-16`}
    >
      <img src={`/assetss/line_1.png`} alt={``} className="absolute top-0 w-full left-0 " />
      <div className={`w-screen flex justify-center md:px-5 items-center space-y-8`}>
        <div className="w-full flex flex-col space-y-5 justify-around items-center text-title">
          <div className="order-3 ipad:order-1 w-full ipad:w-1/4 flex justify-center items-center py-5">
            <img
              data-src="/assetss/banner/Logo/Logo_new.png"
              alt=""
              className="w-full h-auto max-w-[250px]"
            />
          </div>
          <div className="order-1 ipad:order-2  w-full ipad:w-1/2 h-full flex justify-around font-extrabold">
            {/* <div className="flex flex-0 flex-col space-y-5 justify-between">
              {listLink.map((item, idx) => (
                <a
                  key={idx}
                  href={item.link}
                  target={`_blank`}
                  rel={`noreferrer noopener`}
                  className={`text-white font-extrabold`}
                >
                  {item.name}
                </a>
              ))}
            </div> */}
            <div className="flex flex-0 flex-col space-y-5 justify-between items-center">
              {listLink2.map((item, idx) => (
                <a
                  key={idx}
                  href={item.link}
                  target={`_blank`}
                  rel={`noreferrer noopener`}
                  className={`text-white font-extrabold`}
                >
                  {item.name}
                </a>
              ))}
            </div>
          </div>
          <div className="order-2 ipad:order-3  w-full ipad:w-1/4 flex flex-col space-y-2 justify-center items-center">
            <div className="text-white uppercase font-extrabold">JOIN OUR COMMUNITY</div>
            <div className="flex space-x-1">
              {listConnect.map((item, idx) => (
                <a key={idx} href={item.link} target={`_blank`} rel={`noreferrer noopener`}>
                  <img data-src={item.icon} alt={``} className={`transition-all`} />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div
        className={`text-white font-phagspab text-xs mt-10 text-center flex flex-col items-center justify-center gap-1`}
      >
        <span>Copyright © Heroes Land. All Rights Reserved</span>
      </div>
    </footer>
  )
}

export default Footer
