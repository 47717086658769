export const timelineData = [
  {
    time: "Dec 2021",
    id: "1",
    description: "Starting",
    items: [
      {
        completed: true,
        title: "Project Commenced",
      },
      {
        completed: true,
        title: "Game Concept Design",
      },
    ],
  },
  {
    time: "2022 - Q1",
    id: "2",
    description: "Development",
    items: [
      {
        completed: true,
        title: "Internal Test (PvE)",
      },
      {
        completed: true,
        title: "Smart Contract Audit",
      },
      {
        completed: true,
        title: "Daily Quest Feature",
      },
      {
        completed: true,
        title: "Tower Conquest Feature",
      },
    ],
  },
  {
    time: "2022 - Q2",
    id: "3.1",
    description: "Community development",
    items: [
      {
        completed: true,
        title: "Closed Beta Test 1 (PC)",
      },
      {
        completed: true,
        title: "Marketplace",
      },
      {
        completed: true,
        title: "Ascend, Summoning Features",
      },
    ],
  },
  {
    time: "2022 - Q3",
    id: "3.2",
    description: "Community development",
    items: [
      {
        completed: true,
        title: "Closed Beta Test 2 (Adding Mobile Version)",
      },
      {
        completed: true,
        title: "New UI design",
      },
      {
        completed: true,
        title: "PvP Feature",
      },
      {
        completed: true,
        title: "NFT box sale on Binance NFT market",
      },
    ],
    mobile: "long",
  },
  {
    time: "2022 - Q4",
    id: "4.1",
    description: "Growing",
    items: [
      {
        completed: false,
        title: "Clan Feature",
      },
      {
        completed: false,
        title: "Traditional Mode Release",
      },
      {
        completed: false,
        title: "Marketing for Traditional Segment",
      },
      {
        completed: false,
        title: "Seed & Private Sale",
      },
      {
        completed: false,
        title: "INO/ NFT Listing",
      },
    ],
    mobile: "medium",
  },
  {
    time: "2023",
    id: "4.2",
    description: "Growing",
    items: [
      {
        completed: false,
        title: "Land Feature",
      },
      {
        completed: false,
        title: "Gear Crafting Feature",
      },
      {
        completed: false,
        title: "Gear Box and Land Sale",
      },
      {
        completed: false,
        title: "Blockchain Mode Testnet Release for User Adoption",
      },
      {
        completed: false,
        title: "Mainnet Release (dual-gameplay)",
      },
      {
        completed: false,
        title: "IDO",
      },
      {
        completed: false,
        title: "Listing on DEX/CEX",
      },
      {
        completed: false,
        title: "Staking/ Governance",
      },
    ],
    mobile: "long",
  },
  {
    time: "2024",
    id: "6",
    description: "Evolving",
    items: [
      {
        completed: false,
        title: "Concept Design for Heroes Universe Game #2",
      },
      {
        completed: false,
        title: "1st Metaverse-based Land Launched",
      },
      {
        completed: false,
        title: "New Game Content",
      },
    ],
    mobile: "medium",
  },
  {
    time: "2025",
    id: "7",
    description: "Jumping",
    items: [
      {
        completed: false,
        title: "Development of Heroes Universe Game #2",
      },
      {
        completed: false,
        title: "New Game Content",
      },
    ],
  },
]
