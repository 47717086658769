import React, { useLayoutEffect, useState, useEffect } from "react"
import { Navigation, Pagination, Lazy } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import "styles/heroes.scss"
import { heroes } from "data/heroes"
import { PlaySpine } from "assetss/vendors/spine-init"
const Heros = ({ section }) => {
  const heroCards = heroes.map((section, index) => section.heroes.map((hero) => hero.name))
  const [sectionSlide, setSectionSlide] = useState()
  const [heroSlide, setHeroSlide] = useState(0)
  const [heroSwipers, setHeroSwipers] = useState([])
  const [rendering, setRendering] = useState(false)

  useLayoutEffect(() => {
    const heroName = heroCards[sectionSlide]?.[heroSlide]
    if (section !== 3) return
    if (window.spine && heroName) {
      setRendering(true)
      PlaySpine(
        "current-hero-animation",
        `/assetss/heroes/animation/${heroName}/${heroName}`,
        "idle",
        {
          onSuccess: () => {
            setRendering(false)
          },
          onFailed: () => {},
        },
        heroes[sectionSlide].heroes[heroSlide].align || {},
      )
    }
  }, [heroSlide, sectionSlide, section])

  const onHeroCardChange = (swiper) => {
    setRendering(false)
    setHeroSlide(swiper.realIndex)
  }

  const onHeroSectionChange = (swiper) => {
    setRendering(false)
    setSectionSlide(swiper.realIndex)
    heroSwipers[swiper.realIndex].slideTo(heroSlide)
  }

  return (
    <div
      className={`relative w-full h-screen flex flex-col items-center overflow-hidden select-none`}
    >
      {/* <div className="absolute right-0 left-0 top-10 md:top-24 mx-auto z-10">
        <Title title={`HEROES`} />
      </div> */}

      <div className="absolute left-2 ipad:left-12 top-1/2 h-0 z-10">
        <div className="hero-pagination"></div>
      </div>
      <Swiper
        direction={"vertical"}
        nested={true}
        preventInteractionOnTransition={true}
        simulateTouch={false}
        allowTouchMove={false}
        pagination={{
          clickable: true,
          el: ".hero-pagination",
          renderBullet: (index, className) => {
            return `
              <img draggable="false" src="/assetss/heroes/icon/${heroes[index].type}.png" 
              alt="${heroes[index].type}" class="${className} filter saturate-[0.1]" 
              />
            `
          },
          bulletClass: "!m-0 swiper-pagination-bullet",
        }}
        lazy={{ loadPrevNext: true, loadPrevNextAmount: 1 }}
        preloadImages={false}
        modules={[Pagination, Lazy]}
        onSlideChangeTransitionEnd={onHeroSectionChange}
        onSlideChangeTransitionStart={() => setRendering(true)}
        onSwiper={() => setSectionSlide(0)}
      >
        {heroes.map((section, index) => (
          <SwiperSlide key={index} className="h-screen overflow-hidden">
            {({ isActive }) => (
              <div
                className="w-full h-screen overflow-hidden bg-left-bottom bg-cover section-container"
                style={{
                  backgroundImage: `${
                    isActive ? `url(/assetss/heroes/background/${section.type}.png)` : ""
                  }`,
                }}
              >
                <img
                  draggable="false"
                  src={`/assetss/line_1.png`}
                  alt={``}
                  className="absolute top-0 left-1/2 -translate-x-1/2"
                />
                <div className="relative h-screen w-screen flex overflow-hidden">
                  <div className="hero-animation-container absolute bottom-[25vh] w-[300px] h-[300px] md:w-[400px] md:h-[450px] flex flex-col items-center justify-end space-y-3">
                    {isActive && (
                      <canvas
                        id="current-hero-animation"
                        className={`w-full hero-animation origin-bottom transition-all ${
                          rendering ? "opacity-0" : "opacity-1"
                        }`}
                        style={
                          isActive
                            ? { transform: `scale(${section.heroes[heroSlide].scale || 1})` }
                            : { transform: "scale(0)" }
                        }
                      ></canvas>
                    )}
                  </div>
                  <div className="hero-attribute-container absolute bottom-[75vh] md:bottom-16 left-1/2 -translate-x-1/2 md:left-auto md:translate-x-0 md:top-1/2 md:right-0 transform md:-translate-y-1/2 md:flex flex-col w-full md:w-[40%] items-end space-y-5">
                    {!isActive ? null : (
                      <div className="hidden md:flex flex-col items-end space-y-5">
                        <div className="flex justify-center w-[80%] space-x-2">
                          {Array.from(Array(section.heroes[heroSlide].rarity)).map(
                            (item, index) => (
                              <img
                                draggable="false"
                                key={String(sectionSlide) + heroSlide + index}
                                src="/assetss/heroes/atribute/star.png"
                                alt=""
                                className="h-7 w-7 xl:h-auto xl:w-auto"
                              />
                            ),
                          )}
                        </div>
                        <img draggable="false" src="/assetss/heroes/atribute/rarity.png" alt="" />
                        <div className="flex justify-center w-[80%] space-x-2">
                          {section.heroes[heroSlide].class.map((item) => (
                            <img
                              draggable="false"
                              key={item}
                              src={`/assetss/heroes/class/${item}.png`}
                              alt={item}
                              className="h-12 w-12 xl:h-16 xl:w-16"
                            />
                          ))}
                        </div>
                        <img draggable="false" src="/assetss/heroes/atribute/class.png" alt="" />
                        <div className="flex justify-center w-[80%] space-x-2">
                          {Array.from(Array(5)).map((item, index) => (
                            <img
                              draggable="false"
                              src={`/assetss/heroes/animation/${section.heroes[heroSlide].name}/${
                                index + 1
                              }.png`}
                              alt=""
                              className="h-12 w-12 xl:h-16 xl:w-16 transition-all"
                              onError={(event) => (event.target.style.display = "none")}
                              onLoad={(event) => (event.target.style.display = "inline-block")}
                            />
                          ))}
                        </div>
                        <img draggable="false" src="/assetss/heroes/atribute/skill.png" alt="" />
                      </div>
                    )}
                    <div className="md:hidden flex flex-col w-screen px-5 space-y-5 select-none">
                      <div>
                        <Swiper
                          nested={true}
                          allowTouchMove={true}
                          navigation={true}
                          pagination={{
                            el: ".hero-name-pagination",
                            clickable: true,
                          }}
                          slidesPerView={1}
                          modules={[Navigation, Pagination, Lazy]}
                          lazy
                          preloadImages={false}
                          onSlideChange={onHeroCardChange}
                          onSwiper={(swiper) => {
                            setHeroSwipers((_oldSwipers) => {
                              _oldSwipers[index] = swiper
                              return _oldSwipers
                            })
                          }}
                        >
                          {heroCards[index].map((hero, hindex) => (
                            <SwiperSlide key={hero}>
                              <div className="relative flex flex-col items-center space-y-3 z-[40]">
                                <div className="flex justify-center space-x-2">
                                  {Array.from(Array(section.heroes[hindex].rarity)).map(
                                    (item, index) => (
                                      <img
                                        draggable="false"
                                        key={String(sectionSlide) + heroSlide + index}
                                        src="/assetss/heroes/atribute/star.png"
                                        alt=""
                                        className="h-6 w-6"
                                      />
                                    ),
                                  )}
                                </div>
                                <div className="bg-heroType h-10 w-full bg-contain bg-no-repeat bg-center flex items-center justify-center text-title font-extrabold">
                                  {section.heroes[hindex].name}
                                </div>
                              </div>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                      <div className="hero-name-pagination"></div>
                    </div>
                    <div className="hidden md:flex justify-center w-full md:w-[80%] md:pr-2 py-5">
                      <div className="relative flex justify-between">
                        {heroCards[index].map((hero, hindex) => (
                          <img
                            draggable="false"
                            src={`/assetss/heroes/card/${hero}.png`}
                            alt=""
                            className={`object-cover h-[100px] ipad:h-[150px] transition-all duration-200 ease-in-out ${
                              heroSlide === hindex ? "order-2 transform scale-125" : ""
                            } ${
                              [0, 1, 2].filter((item) => item !== heroSlide).includes(hindex)
                                ? Math.min(...[0, 1, 2].filter((item) => item !== heroSlide)) ===
                                  hindex
                                  ? "order-1"
                                  : "order-3"
                                : ""
                            }`}
                            onClick={() => setHeroSlide(hindex)}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default Heros
