import React, { useEffect, useState } from "react"
import Banner from "layouts/Banner"
import GameStory from "layouts/GameStory"
import Heros from "layouts/Heros"
import Token from "layouts/Token"
// import Tokenomic from "layouts/Tokenomic"
import CoreTeams from "layouts/CoreTeams"
import Investor from "layouts/Investor"
import Footer from "layouts/Footer"
import Header from "layouts/Header"
import { Element } from "react-scroll"
import Roadmap from "layouts/Roadmap"
import GameFeatures from "layouts/GameFeatures"
import Loading from "layouts/Loading"
import JoinOur from "layouts/JoinOur"
import { PlaySpine } from "assetss/vendors/spine-init"
// import Advisor from "layouts/Advisor"
import ReactFullpage from "@fullpage/react-fullpage"
import Popup from "./Popup"

export const BackgroundVideo = ({ children }) => {
  return (
    <div className={`relative w-full`}>
      <video
        autoPlay
        muted
        loop
        id="myVideo"
        className={`hidden ipad:inline object-fill fixed w-full h-screen bottom-0 right-0 top-0 left-0 z-0 p-0`}
      >
        <source src="/assetss/video_bg.mp4" type="video/mp4" />
      </video>
      {children}
    </div>
  )
}

const TOTAL_IMAGE = 1

// eslint-disable-next-line no-unused-vars
export let players

const Layout = () => {
  const [loadImg, setLoadImg] = useState(0)
  const [fpApi, setFpApi] = useState({})
  const [headerSection, setHeaderSection] = useState(0)
  const [spineStatus, setSpineStatus] = useState({
    logoFooter: true,
    logoFooterMobile: true,
    bannerIntro: true,
    bannerIntroMobile: true,
    henena: true,
    gordin: true,
    kaarol: true,
    lilna: true,
    shine: true,
    tokenHls: true,
    tokenHala: true,
  })

  const onLoadSuccess = (prop) => {
    setSpineStatus((spineStatus) => ({ ...spineStatus, [prop]: true }))
  }

  const onLoadFailed = (prop) => {
    setSpineStatus((spineStatus) => ({ ...spineStatus, [prop]: false }))
  }

  const loadImageDone = () => {
    setLoadImg((img) => img + 1)
  }

  const initSkelPlayer = () => {
    if (window.spine) {
      PlaySpine(
        "bannerIntro",
        "/assetss/banner/bg_landing_new/Chim",
        "idle",
        {
          onSuccess: () => {
            loadImageDone()
            onLoadSuccess("bannerIntro")
          },
          onFailed: () => {
            loadImageDone()
            onLoadFailed("bannerIntro")
          },
        },
        { bottom: -1, preferHeight: false },
      )
    }
  }

  useEffect(() => {
    window.addEventListener("load", initSkelPlayer)
  }, [])

  return (
    <div>
      {loadImg < TOTAL_IMAGE && <Loading percent={(loadImg / TOTAL_IMAGE) * 100} />}
      {/* <Popup /> */}
      <BackgroundVideo>
        <div className={`mx-auto bg-defaultBg ipad:bg-transparent`}>
          {/* <Header fullpageApi={fpApi} section={headerSection} /> */}
          {/* <JoinOur section={headerSection} /> */}
          <ReactFullpage
            verticalCentered={false}
            scrollBar={false}
            responsiveWidth={1024}
            scrollingSpeed={500}
            lazyLoading={true}
            // normalScrollElements="#banner, #footer"
            onLeave={(origin, destination, direction) => {
              setHeaderSection(destination.index)
            }}
            render={({ state, fullpageApi }) => {
              setFpApi(fullpageApi)
              return (
                <>
                  <ReactFullpage.Wrapper>
                    <Element className="section" name="banner">
                      <Banner spineStatus={spineStatus} />
                    </Element>
                    {/* <Element className="section" name="gameStory">
                      <GameStory />
                    </Element>
                    <Element className="section fp-auto-height" name="gameFutures">
                      <GameFeatures />
                    </Element>
                    <Element className="section fp-auto-height" name="heroes">
                      <Heros section={headerSection} />
                    </Element>
                    <Element className="section" name="token">
                      <Token section={headerSection} />
                    </Element>
                    <Element className="section fp-auto-height" name="roadmap">
                      <Roadmap section={headerSection} />
                    </Element>
                    <Element className="section" name="coreTeams" id="core-team">
                      <CoreTeams />
                    </Element>
                    <Element className="section fp-auto-height" name="investor" id="investor">
                      <Investor check={state?.destination?.index} />
                    </Element> */}
                    <Footer className="section" spineStatus={spineStatus} id="footer" />
                  </ReactFullpage.Wrapper>
                </>
              )
            }}
          />
        </div>
      </BackgroundVideo>
    </div>
  )
}

export default Layout
