export const heroes = [
  {
    type: "beast",
    heroes: [
      {
        name: "Zetylios",
        rarity: 5,
        class: ["light", "berserker"],
        skill: 5,
      },
      {
        name: "Sigark",
        rarity: 3,
        class: ["fire", "assassin"],
        skill: 2,
        scale: 0.85,
      },
      {
        name: "Kaarol",
        rarity: 1,
        class: ["nature", "warrior"],
        skill: 1,
        scale: 0.9,
      },
    ],
  },
  {
    type: "human",
    heroes: [
      {
        name: "Jogo",
        rarity: 1,
        class: ["light", "archer"],
        skill: 3,
        scale: 0.9,
      },
      {
        name: "Lilna",
        rarity: 2,
        class: ["shadow", "assassin"],
        skill: 3,
        scale: 0.8,
      },
      {
        name: "Vedora",
        rarity: 1,
        class: ["shadow", "wizard"],
        skill: 4,
        scale: 0.8,
      },
    ],
  },
  {
    type: "elf",
    heroes: [
      {
        name: "Shine",
        rarity: 5,
        class: ["nature", "archer"],
        skill: 4,
        scale: 0.8,
        align: { addWidth: 100 },
      },
      {
        name: "Henena",
        rarity: 4,
        class: ["fire", "wizard"],
        skill: 4,
        scale: 0.75,
        align: { addHeight: 120 },
      },
      {
        name: "Leah",
        rarity: 1,
        class: ["nature", "berserker"],
        skill: 4,
        scale: 0.7,
        align: { addHeight: 150 },
      },
    ],
  },
  {
    type: "orc",
    heroes: [
      {
        name: "Nizver",
        rarity: 2,
        class: ["ice", "assassin"],
        skill: 4,
        scale: 0.95,
      },
      {
        name: "Magroz",
        rarity: 3,
        class: ["ice", "warrior"],
        skill: 4,
        scale: 1,
      },
      {
        name: "Magent",
        rarity: 1,
        class: ["fire", "knight"],
        skill: 4,
        scale: 0.85,
      },
    ],
  },
  {
    type: "god",
    heroes: [
      {
        name: "Gordil",
        rarity: 3,
        class: ["light", "wizard"],
        skill: 4,
        scale: 0.9,
      },
      {
        name: "Nez",
        rarity: 2,
        class: ["ice", "berserker"],
        skill: 4,
        scale: 1,
        align: { addWidth: 100 },
      },
      {
        name: "Altiz",
        rarity: 2,
        class: ["fire", "knight"],
        skill: 4,
        scale: 0.85,
        align: { addWidth: 100 },
      },
    ],
  },
  {
    type: "undead",
    heroes: [
      {
        name: "Baektom",
        rarity: 1,
        class: ["ice", "assassin"],
        skill: 4,
        scale: 0.75,
      },
      {
        name: "Zynkii",
        rarity: 4,
        class: ["shadow", "knight"],
        skill: 4,
        scale: 1,
      },
      {
        name: "Harley",
        rarity: 3,
        class: ["nature", "archer"],
        skill: 4,
        scale: 0.95,
      },
    ],
  },
  {
    type: "demon",
    heroes: [
      {
        name: "Rarfel",
        rarity: 2,
        class: ["light", "archer"],
        skill: 4,
        scale: 0.9,
      },
      {
        name: "Stekaz",
        rarity: 1,
        class: ["light", "berserker"],
        skill: 4,
        scale: 0.75,
        align: { addWidth: 150 },
      },
      {
        name: "Jeemil",
        rarity: 4,
        class: ["shadow", "knight"],
        skill: 4,
        scale: 0.85,
      },
    ],
  },
]
