import Title from "components/title"
import parse from "html-react-parser"
import React from "react"
import "styles/team.scss"
import { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

// eslint-disable-next-line no-unused-vars
const breakpoint = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 2 },
  { width: 800, itemsToShow: 3 },
  { width: 1024, itemsToShow: 4 },
  { width: 1350, itemsToShow: 5 },
  { width: 1600, itemsToShow: 5 },
  { width: 1800, itemsToShow: 5 },
  { width: 2000, itemsToShow: 6 },
]

const pathToImageTeam = "/assetss/core-teams"

const teamList = [
  // {
  //   image: `${pathToImageTeam}/phucnguyen.png`,
  //   name: "Phuc Nguyen",
  //   position: "Advisor",
  //   description: `<p>Founder, CFO & COO at Sotatek</p>
  //   <p>Former CTO of a CEX under Bitmex's portfolio</p>
  //   <p>Former Google Summer of Code Samsung Smart App Challenge winner</p>`,
  //   linkedin: "linkedin",
  // },
  {
    image: `${pathToImageTeam}/thangbui.png`,
    name: "Thang Bui",
    position: "CEO",
    description:
      "20-year experience in software industry, built blockchain COE, incubated enterprise blockchain platform; spent 16 years working in different overseas branches including the latest 3 years in Japan and 1 year in Germany in the role of CTO",
    linkedin: "https://www.linkedin.com/in/thangbv/",
  },
  {
    image: `${pathToImageTeam}/thuongdinh.png`,
    name: "Thuong Dinh",
    position: "Game Director",
    description:
      "Co-Founder Sky Games, 12 years of in-depth and dedicated experience in planning and implementing game projects",
    linkedin: "linkedin",
  },

  {
    image: `${pathToImageTeam}/xuyendao.png`,
    name: "Xuyen Dao",
    position: "Product Manager",
    description:
      "CTO Sky Games, 11 years of experience in the software and game development industry",
    linkedin: "https://www.linkedin.com/in/dao-xuyen-6327b0235/",
  },
  {
    image: `${pathToImageTeam}/jamesnguyen.png`,
    name: "James Nguyen",
    position: "CMO",
    description:
      "Former Creative Manager of Tonkin Media, 6 years of experience in the Marketing Industry including 2 years of experience in GameFi projects",
    linkedin: "https://www.linkedin.com/in/nguyen-cong-thai-son",
  },
  {
    image: `${pathToImageTeam}/robertvu.png`,
    name: "Robert Vu",
    position: "Solution Architect",
    description:
      "12 years of experience in software development and in software architecture design",
    linkedin: "https://www.linkedin.com/in/robert-vu-90583037/",
  },
  {
    image: `${pathToImageTeam}/trungle.png`,
    name: "Trung Le",
    position: "DApp Leader",
    description:
      "Front-End Developer with 6 years experience in both outsourcing and product environment",
    linkedin: "https://www.linkedin.com/in/lethanhtrung-vn/",
  },
  {
    image: `${pathToImageTeam}/kimhoang.png`,
    name: "Kim Hoang",
    position: "Art Leader",
    description: "An experienced artist in the game industry with more than 5 years",
    linkedin: "https://www.linkedin.com/in/kim-hoang-774870235/",
  },
  {
    image: `${pathToImageTeam}/kyvu.png`,
    name: "Ky Vu",
    position: "Animation Leader",
    description: "6 years of Animation experience in the game industry",
    linkedin: "https://www.linkedin.com/in/vu-thanh-ky-373701235/",
  },
  {
    image: `${pathToImageTeam}/dungnguyen.png`,
    name: "Dung Nguyen",
    position: "UI/UX Design",
    description:
      "Creative artist with 4 years experience in UI/UX design and take part in many domestic and foreign projects",
    linkedin: "https://www.linkedin.com/in/dung-ph%C6%B0%C6%A1ng-b937ab235/",
  },
  {
    image: `${pathToImageTeam}/cuongdo.png`,
    name: "Cuong Do",
    position: "Game Designer",
    description: "Game Designer, in-depth knowledge and competence with 6 years of experience",
    linkedin: "https://www.linkedin.com/in/cường-đv-8947b2235/",
  },
]

const CoreTeams = () => {
  return (
    <div
      className={`relative w-full min-h-screen bg-transparent pb-10 pt-32 flex flex-col items-center justify-center px-5 ipad:px-5`}
    >
      <img src={`/assetss/line_1.png`} alt={``} className="absolute top-0" />
      <div className="absolute top-20">
        <Title title={`CORE TEAM`} />
      </div>
      <div className="relative px-10 w-full h-full flex items-center">
        <div className="team-prev-button swiper-button-prev absolute !top-[60%] left-0"></div>
        <div className="team-next-button swiper-button-next absolute !top-[60%] right-0"></div>
        <Swiper
          navigation={{
            nextEl: ".team-next-button",
            prevEl: ".team-prev-button",
          }}
          pagination={{
            el: ".team-pagination",
            clickable: true,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            640: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
            1280: {
              slidesPerView: 4,
            },
            1536: {
              slidesPerView: 5,
            },
          }}
          modules={[Navigation, Pagination]}
        >
          {teamList.map((info, idx) => (
            <SwiperSlide key={idx}>
              <div
                className={`bg-transparent bg-cover bg-center my-10 team-card flex flex-col items-center`}
              >
                <div>
                  <img data-src={info.image} alt={``} className={`w-[250px] object-contain`} />
                </div>
                <div className={`text-center mt-10 flex flex-col items-center space-y-1`}>
                  <div className={`text-white font-extrabold`}>{info.name}</div>
                  <div className={`text-highlight font-extrabold text-sm`}>{info.position}</div>
                  <div>
                    <a href={info.linkedin} target="_blank" rel="noreferrer">
                      <img data-src={`${pathToImageTeam}/btn_linkedin.png`} alt="" />
                    </a>
                  </div>
                  <div
                    className={`popup invisible z-50 text-title text-xs mt-2 w-[230px] bg-teamPopup h-[162px] bg-no-repeat pt-[40px] px-[5px] popup flex-col transition-all`}
                  >
                    {parse(info.description)}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="absolute !bottom-32 !left-1/2 !-translate-x-1/2 flex space-x-3 justify-center team-pagination cursor-pointer z-0"></div>
      </div>
      {/*</div>*/}
    </div>
  )
}

export default CoreTeams
